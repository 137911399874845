<template>
  <v-container style="margin-bottom: 100px">
    <v-row class="sc-title mt-7">
      <v-col md="8" sm="6">
        <div>
          <h1>FOLLOW UP CALLS</h1>
        </div>
      </v-col>
      <v-col md="4" sm="6">
        <div class="text-right">
          <v-btn color="primary" @click="$router.push({ name: 'Evaluation' })"
            >Back</v-btn
          >
        </div>
      </v-col>
    </v-row>
    <v-row class="sc-title mt-7">
      <v-col md="8" sm="6">
        <div>
          <h1>RANDY WHITE</h1>
        </div>
      </v-col>
      <v-col md="4" sm="6">
        <div class="text-right">
          <v-btn color="primary" @click="$router.push({ name: 'ScheduleFU2' })"
            >Schedule FU</v-btn
          >
        </div>
      </v-col>
    </v-row>
    <v-data-table :headers="headers" :items="items">
      <template v-slot:item.phone="{ item }">
        <a :href="'tel:' + item.phone">{{ item.phone }}</a>
      </template>

      <template v-slot:item.mark_complete="{ item }">
        <v-radio-group v-model="item.mark_complete" row>
          <v-radio label="Mark Complete" value="radio-1"></v-radio>
          <v-radio label="Skip" value="radio-2"></v-radio>
        </v-radio-group>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
// import Datatable from "../../components/Datatable/Datatable";
import ripple from "vuetify/lib/directives";

export default {
  // components: { Datatable },
  directives: {
    ripple,
  },
  data() {
    return {
      componentURL: "",
      row: null,
      headers: [
        { text: "Customer", value: "customer" },
        { text: "FU Type", value: "fuTypes" },
        { text: "Phone", value: "phone" },
        { text: "Text", value: "text" },
        { text: "Comments", value: "comments" },
        { text: "Mark Complete / Skip", value: "mark_complete" },
      ],
      fuItems: ["Customer", "Phone", "Text", "Comments"],
      items: [
        {
          customer: "customer1",
          fuTypes: "2Day B4 Eval",
          phone: "87494648",
          text: "87494648",
          comments: "Lorem Ipsum is simply dummy.",
          mark_complete: false,
        },
        {
          customer: "",
          fuTypes: "7DayBidFU",
          phone: "",
          text: "",
          comments: "",
          mark_complete: true,
        },
        {
          customer: "",
          fuTypes: "BidQuestion",
          phone: "",
          text: "",
          comments: "",
          mark_complete: true,
        },
        {
          customer: "",
          fuTypes: "RepScheduled",
          phone: "",
          text: "",
          comments: "",
          mark_complete: false,
        },
      ],
    };
  },
};
</script>

<style type="text/css">
.fuTypes {
  width: 25%;
}
</style>
